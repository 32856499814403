import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import LogoWhite from "../../assets/images/logo-white.png";
import CESDImage from "../../assets/images/Custom-Enterprise-Software-Development.png";
import LAMImage from "../../assets/images/Legacy-Application-Modernisation.png";
import SPRImage from "../../assets/images/Software-Project-Rescue.png";
import PDImage from "../../assets/images/Product-Design.png";
import MVPImage from "../../assets/images/Minimum-Viable-Product.png";
import SIImage from "../../assets/images/Software-Integration.png";
import WADImage from "../../assets/images/Web-Application-Development.png";
import MDImage from "../../assets/images/Mobile-Development.png";
import { useWindowScroll } from "../../hooks/useWindowScroll";
import Button from "../Button/Button";
import Text from "../Text/Text";
import { ArrowDownIcon, MenuIcon } from "../Icons/Icon";
import "./NavBar.css";

const ITEMS = [
  {
    title: "HOW WE WORK",
    path: "how-we-work",
  },
  {
    title: "SERVICES",
    path: "services",
    items: [
      {
        image: WADImage,
        title: "Web Application Development",
        description:
          "Build superior digital products and speed up time to market and with Discipline Agile Delivery.",
        path: "/services/web-application-development",
      },
      {
        image: MDImage,
        title: "Mobile Development",
        description:
          "Think mobile first for your product and craft an integrated user experience across all devices.",
        path: "/services/mobile-application-development",
      },
      {
        image: PDImage,
        title: "Product Design",
        description:
          "Obtain a clear vision and solve complex problems fast with Product Design Sprints",
        path: "/services/product-design",
      },
    ],
    generalPath: "/services",
    links: [
      {
        label: "Team Augmentation",
        path: "/services/team-augmentation",
      },
      {
        label: "Software Project Rescue",
        path: "/services/project-rescue",
      },
      {
        label: "Dev Ops",
        path: "/services/dev-ops",
      },
      {
        label: "Blockchain",
        path: "/services/blockchain-development",
      },
    ],
  },
  {
    title: "ENTERPRISES",
    path: "enterprises",
    generalPath: "/enterprises",
    items: [
      {
        image: CESDImage,
        title: "Custom Enterprise Software Development",
        description:
          "Build custom application for your business infrastructure and solve your key pain points that off that shelf products cannot support.",
        path: "/enterprises/application-development",
      },
      {
        image: LAMImage,
        title: "Legacy Application Modernization",
        description:
          "Rebuild and replacement is not the only option to modernise your business application. ",
        path: "/enterprises/legacy-application",
      },
      {
        image: SIImage,
        title: "Software Integration",
        description:
          "Scale your business faster by connecting moving parts with integrations and API development.",
        path: "/enterprises/software-integration",
      },
    ],
    links: [
      {
        label: "Enterprise Architecture Advisory",
        path: "/enterprises",
      },
    ],
  },
  {
    title: "STARTUPS",
    path: "startups",
    generalPath: "/startups",
    items: [
      {
        image: MVPImage,
        title: "Minimum Viable Product",
        description:
          "Create and iterate your MVP fast and bring your ideas to life with what your users need and now what you think your users want.",
        path: "/startups/mvp",
      },
      {
        image: SPRImage,
        title: "Software Project Rescue",
        description:
          "Projects go wrong for many reasons, however, that might not be the end of the road for you. Agile Lab are experience with project recovery and can help you get back on track.",
        path: "/services/project-rescue",
      },
      {
        image: PDImage,
        title: "Product Design",
        description:
          "Build a user centric product with early validation and a strong insight of your user needs.",
        path: "/services/product-design",
      },
    ],
    links: [],
  },
  {
    title: "CASE STUDIES",
    path: "case-studies",
  },
  {
    title: "INSIGHTS",
    path: "https://blog.agilelab.sg/",
  },
  {
    title: "COMPANY",
    path: "company",
  },
];

export default function NavBar() {
  const { pathname } = useLocation();
  const { y } = useWindowScroll();
  const show = y > 0;

  const shouldChange = [
    "/services",
    "/enterprises",
    "/case-studies/eu-yan-sang",
    "/case-studies/popular-bookstores",
    "/case-studies/tzu-chi",
    "/case-studies/chiropractic-singapore",
    "/case-studies/charles-monat",
    "/case-studies/dbs",
    "/case-studies/exceltec",
    "/case-studies/onesystems-technologies",
  ].includes(pathname);

  const BaseLogo = shouldChange ? LogoWhite : Logo;
  const textColor = shouldChange ? "text-white" : "";

  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const [navbarVisible, setNavbarVisible] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const toggleDropdown = (title, event) => {
    event.stopPropagation();
    setVisibleDropdown(visibleDropdown === title ? null : title);
  };

  const handleLinkClick = () => {
    setNavbarVisible(false);
    setMenuIsOpen(false);
    setVisibleDropdown(null);

    const body = document.body;

    body.classList.remove('no-scroll');
  };

  const toggleNavbar = () => {
    setNavbarVisible(!navbarVisible);
    setMenuIsOpen(!menuIsOpen);

    const body = document.body;

    if (!navbarVisible) {
      body.classList.add('no-scroll');
    } else {
      body.classList.remove('no-scroll');
    }
  };

  return (
    <>
      <nav
        className={`desktop-navbar group/nav w-full fixed top-0 z-20 px-4 transition-all hover:shadow-lg hover:bg-white duration-500 ${
          show ? "shadow-lg bg-white" : ""
        } flex`}
      >
        <div className="flex-1">
          <Link to="/">
            <img
              src={BaseLogo}
              alt="Agile lab"
              className={`my-4 w-[120px] md:w-[200px] ${
                show ? "hidden" : "group-hover/nav:hidden"
              }`}
            />
            <img
              src={Logo}
              alt="Agile lab"
              className={`mobile-logo my-4 w-[120px] md:w-[200px] ${
                show ? "" : "hidden group-hover/nav:block"
              }`}
            />
          </Link>
        </div>
        <ul className="hidden xl:flex self-stretch">
          {ITEMS.map(({ title, path, items, links, generalPath }) => (
            <li key={title} className="group/item group/visible">
              {title === "INSIGHTS" ? (
                <a
                  href={path}
                  rel="noreferrer"
                  className={`grid place-content-center group-hover/nav:text-black h-full px-4 group-hover/item:border-b-4 ${
                    show ? "" : textColor
                  } border-b-red-600 group-hover/item:!text-red-600 transition-all ${
                    pathname.includes(path)
                      ? "border-b-red-600 border-b-4 !text-red-600"
                      : ""
                  }`}
                  target="_blank"
                >
                  {title}
                </a>
              ) : (
                <Link
                  to={path}
                  className={`grid place-content-center group-hover/nav:text-black h-full px-4 group-hover/item:border-b-4 ${
                    show ? "" : textColor
                  } border-b-red-600 group-hover/item:!text-red-600 transition-all ${
                    pathname.includes(path)
                      ? "border-b-red-600 border-b-4 !text-red-600"
                      : ""
                  }`}
                >
                  {title}
                </Link>
              )}

              {items && (
                <div
                  className="bg-white w-full left-0 top-full absolute border-t hidden group-hover/visible:block text-black shadow-md"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="container mx-auto grid grid-cols-4 px-4 pt-4 pb-8 gap-8">
                    {items.map((item, index) => (
                      <Link key={index} to={item.path} className="">
                        <img loading="lazy" src={item.image} alt={item.image} />
                        <Text type="p" size="sm" className="font-bold mt-4">
                          {item.title}
                        </Text>
                        <Text type="p" size="xs" className="mt-4">
                          {item.description}
                        </Text>
                        <Button className="mt-4" variant="outline" size="sm">
                          READ MORE
                        </Button>
                      </Link>
                    ))}
                    <ul className="px-8">
                      {links.map((item, index) => (
                        <li key={index} className="mb-4 text-lg">
                          <Link className="hover:text-red-600" to={item.path}>
                            {item.label}
                          </Link>
                        </li>
                      ))}
                      <li className="mt-8">
                        <Button path={generalPath} variant="outline">
                          ALL SERVICES
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
        <div className="grid place-content-center self-stretch pl-4 xl:mr-0 mr-12">
          <Button path={"contact-us"} size="sm">
            CONTACT
          </Button>
        </div>
      </nav>
      <div className="menu-icon-container fixed z-30 right-3 xl:hidden ml-6">
        <MenuIcon onClick={toggleNavbar} isOpen={menuIsOpen} />
      </div>
      <nav className={`mobile-navbar fixed w-full bg-white transition-all ${navbarVisible ? 'show' : ''}`}>
        <ul>
          {ITEMS.map(({ title, path, items }) => (
            <li key={title} className="group px-5 py-5">
              <div className='flex items-center'>
                {title === "INSIGHTS" ? (
                  <a
                    href={path}
                    rel="noreferrer"
                    className={`h-full w-full hover:text-red-600 ${
                      show ? "" : "text-black"
                    } transition-all ${
                      pathname.includes(path)
                        ? " !text-red-600"
                        : ""
                    }`}
                    target="_blank"
                    onClick={handleLinkClick}
                  >
                    {title}
                  </a>
                ) : (
                  <Link
                    to={path}
                    className={`h-full w-full pr-3 ${
                      show ? "" : "text-black"
                    } transition-all ${
                      pathname.includes(path)
                        ? " !text-red-600"
                        : ""
                    }`}
                    onClick={handleLinkClick}
                  >
                    {title}
                  </Link>
                )}
                {items && items.length > 0 && (
                  <button onClick={(e) => toggleDropdown(title, e)} className="dropdown-toggle">
                    <ArrowDownIcon width="18px" height="18px" />
                  </button>
                )}
              </div>
              {items && items.length > 0 && visibleDropdown === title && (
                <ul className="dropdown-menu pl-10">
                  {items.map((item) => (
                    <li key={item.title} className="px-5 py-5">
                      <Link to={item.path} className="hover:text-red-600 h-full w-full" onClick={handleLinkClick}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
}
